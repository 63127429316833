import React, { useEffect, useRef, useState } from "react";
import { AxiosLibGlobalReq, AxiosLibBillApp } from "../../../helpers/AxiosLibs";
import { AuthDecodeToken } from "../../authentications/reducers/AuthRedux";
import { RemoveEmptyValue } from "../../../helpers/CommonHelpers";
import {
  Link,
  Navigate,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
//import { Editor } from "@tinymce/tinymce-react";
import {
  MessageError,
  MessageSuccess,
  openModal,
} from "../../layouts/modals/Modals";
import { RenderAccessWidgets } from "../RenderAccessUser";
import { AlertNofif } from "../../layouts/alerts/AlertsUI";
import { formatRupiah } from "./components/FormAtributes";

const Token = AuthDecodeToken();

export function ItemForm() {
  const context = useOutletContext();
  const widget_create = RenderAccessWidgets(context.widgets, 25);
  const navigate = useNavigate();
  let params = useParams();
  const item_id = params.item_id;
  const [error, setError] = useState("");

  const editorRef = useRef(null);

  const objParam = {
    item_id: 0,
    code_name: "",
    item_name: "",
    price: "",
    label: "",
    description: "",
    created_by: Token.identity.given_name,
  };
  const [post, setPost] = useState(objParam);
  const [temp, setTemp] = useState({ loading: false, data: [], message: "" });
  useEffect(() => {
    if (item_id) {
      GetDataByID(item_id, setTemp, setPost);
    } else {
      // Generate new code for new items
      setPost(prev => ({ ...prev, code_name: generateCode() }));
    }
  }, [item_id]);

  const [submitPost, setSubmitPost] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const generateCode = () => {
    const now = new Date();
    const timestamp = now.getTime().toString(36).toUpperCase(); // Convert to base36
    const encodedTime = timestamp.slice(-4); // Take last 4 characters
    return `EV${encodedTime}`;
  };

  const CreateItem = (param) => {
    setSubmitPost({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/item/save"
    ).then((response) => {
      if (response.error) {
        setSubmitPost({ loading: false, data: [], message: response.error });
      } else if (response.data && response.status === 200) {
        setSubmitPost({
          loading: false,
          data: response.data,
          message: "Successfully Saved",
        });
        openModal({
          message: <MessageSuccess message={"Successfully Saved"} />,
        });
        setTimeout(() => {
          openModal({ open: false });
          navigate("/items");
        }, 1000);
      } else {
        setSubmitPost({ loading: false, data: [], message: "Failed saved" });
      }
    });
  };

  const UpdateItem = (param) => {
    setSubmitPost({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/item/update"
    ).then((response) => {
      if (response.error) {
        setSubmitPost({ loading: false, data: [], message: response.error });
        openModal({
          message: <MessageError message={response.error} />,
        });
      } else if (response.data && response.status === 200) {
        setSubmitPost({
          loading: false,
          data: response.data,
          message: "Successfully Updated",
        });
        openModal({
          message: <MessageSuccess message={"Successfully Updated"} />,
        });
        setTimeout(() => {
          openModal({ open: false });
          navigate("/items");
        }, 1000);
      } else {
        setSubmitPost({ loading: false, data: [], message: "Failed saved" });
      }
    });
  };

  const SubmitForm = (e) => {
    e.preventDefault();
    if (post.code_name && post.price && post.label) {
      const param = RemoveEmptyValue(post);
      if (!post.label) {
        param.label = '';
      }
      if (post.item_id) {
        param.item_id = param.item_id;
        UpdateItem(param);
      } else {
        CreateItem(param);
      }
    } else {
      openModal({
        message: (
          <MessageError message={"Please fill out the form with correctly"} />
        ),
      });
    }
  };

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };

  const handleName = (event) => {
    const inputValue = event.target.value;
    const formattedValue = toTitleCase(inputValue);
    setPost({ ...post, label: formattedValue });
    console.log(formattedValue);
  };

  return widget_create === true ? (
    <div className="card">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Form Item</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            Please fill up the form with correctly
          </span>
        </h3>
      </div>
      <div className="card-body">
        <form
          method="post"
          autoComplete="off"
          id="form-post"
          onSubmit={(e) => SubmitForm(e)}
        >
          <div className="mb-5">
            <label className="required fw-bolder">Item Code</label>
            <input
              type="text"
              required
              name="Code_name"
              className="form-control form-control-solid"
              value={post.code_name}
              readOnly="true"
            />
          </div>
          <div className="mb-5">
            {/* <label className="required fw-bolder">Item Name</label> */}
            <input
              type="hidden"
              required
              name="Item_name"
              className="form-control form-control-solid"
              value={post.item_name}
              readOnly="true"
            />
          </div>


          <div className="mb-5">
            <label className="required fw-bolder">
              Label
            </label>
            <input
              type="text"
              name="Label"
              className="form-control"
              value={post.label}
              onChange={handleName}
            />
          </div>
          <div className="mb-5">
            <label className="required fw-bolder">Price</label>
            <input
              type="text"
              required
              name="Price"
              className="form-control form-control-solid"
              value={post.price ? `Rp ${formatRupiah(post.price)}` : ''}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^\d]/g, '');
                setPost({ ...post, price: numericValue });
              }}
            />
          </div>
          <div className="mb-5">
            <label className=" fw-bolder">Description</label>
            <input
              type="text"
              name="Label"
              className="form-control"
              value={post.description}
              onChange={(e) =>
                setPost({ ...post, description: e.target.value })
              }
            />
          </div>
          {submitPost.message ? (
            <div className="bg-light-info p-3 rounded border border-info text-info text-capitalize mb-5">
              {submitPost.message}
            </div>
          ) : (
            ""
          )}

          <div className="text-end mt-10 d-flex justify-content-between">
            <Link className="btn btn-lg btn-light fw-bold me-3" to={"/items"}>
              Cancel
            </Link>
            <button
              className="btn btn-lg btn-primary fw-bold btn-submit"
              type="submit"
              disabled={submitPost.loading}
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <AlertNofif
      title={"Information"}
      messages={
        "You don't have permission to access this resource. Please contact the administrator."
      }
      color="danger"
    />
  );
}

const GetDataByID = (item_id, setTemp, setPost) => {
  setTemp({ loading: true, data: [], message: "" });
  const parameter = {
    header: {},
    method: "get",
  };
  AxiosLibGlobalReq(
    parameter,
    process.env.REACT_APP_EXPRESS_API + "/api/item/" + item_id
  ).then((response) => {
    if (response.error) {
      setTemp({ loading: false, data: [], message: response.error });
    } else if (response.data && response.status === 200) {
      const result = response.data;
      setTemp({
        loading: false,
        data: result,
        message: "Successfully Saved",
      });
      setPost({
        item_id: result.item_id,
        code_name: result.code_name,
        item_name: result.item_name,
        price: result.price,
        label: result.label,
        description: result.description,
        created_by: Token.identity.given_name,
      });

      //console.log(response.data);
    } else {
      setTemp({ loading: false, data: [], message: "Failed saved" });
    }
  });
};
