import React from "react";
import moment from "moment";
import { useState } from "react";
import {
  AxiosLibGlobalReq,
  AxiosLibGlobalReqModule,
} from "../../../../helpers/AxiosLibs";
import { useEffect } from "react";
import { LoadingBars } from "../../../layouts/loadbars/LoadingBars";
import {
  MessageError,
  MessageSuccess,
  openModal,
} from "../../../layouts/modals/Modals";
import { AuthDecodeToken } from "../../../authentications/reducers/AuthRedux";
import { ButtonPrimary } from "../../../layouts/buttons";
import { RemoveEmptyValue } from "../../../../helpers/CommonHelpers";
import Select from 'react-select';

export default function FilterData({ filterDt, setFilterDt, ReloadData }) {
  var Token = AuthDecodeToken();

  const [eventList, setEventList] = useState({ loading: false, data: [], message: "" });
  const [eId, setEid] = useState(0);
  const optionsEvent = eventList.data.map(item => ({
    value: item.event_id,
    label: item.event_id + ' - ' + decodeURIComponent(item.short_title)
  }));
  const [selectedOptionEvent, setSelectedOptionEvent] = useState({ value: filterDt.event_id, label: filterDt.event_label });
  const handleEvent = (selected) => {
    //console.log(selected)
    if (selected) {
      const selectedCode = selected.value;  // This is the sp_code
      const selectedName = selected.label;
      setSelectedOptionEvent(selected);
      //setEid(selectedCode);

    } else {
      setSelectedOptionEvent(null);
    }

  };
  
  const HandlerClear = () => {
     setFilterDt(prevState => ({
      ...prevState,
      event_id: 0,
      event_label: ''
    }));
    setSelectedOptionEvent(null);
    openModal({ open: false });
    ReloadData()
  };
  useEffect(() => {
    GetEvent(setEventList);
  }, []);


  const SubmitForm = (e) => {
    e.preventDefault();
    setFilterDt(prevState => ({
      ...prevState,
      event_id: selectedOptionEvent.value,
      event_label: selectedOptionEvent.label
    }));openModal({ open: false });
    ReloadData();
  };


  return Object.values(eventList).length > 0 ? (
    <div className="detail-item">
      <form
        method="post"
        autoComplete="off"
        id="form-import"
        onSubmit={(e) => SubmitForm(e)} >

        <div className="mb-5">
          <label className=" fw-bolder">Event Title</label>
          <Select
            value={selectedOptionEvent}
            onChange={handleEvent}
            options={optionsEvent}
            isSearchable={true}
            placeholder="Select an Event..."
          />
        </div>

        <div className="my-10">
          <div className="d-flex justify-content-end align-items-center">

            <div className="btn-group">
            <ButtonPrimary
                className="btn-lg"
                type="submit"
              >
                <i class="ki-duotone ki-filter fs-3 text-white me-1">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </i>
                <span className="me-2">Apply Filter</span>
              </ButtonPrimary>

              <button className="btn btn-sm bg-secondary fs-7" type="button" onClick={() => HandlerClear()}>
                            <span className="text-black fw-bold">
                                Clear
                            </span>
                        </button>
                        
            </div>
          </div>
        </div>
      </form>
    </div >
  ) :
    <></>

}

const GetEvent = (setEventList) => {
  setEventList({ loading: true, data: [], message: "" });
  const parameter = {
    header: {},
    method: "post",
  };
  AxiosLibGlobalReq(
    parameter,
    process.env.REACT_APP_EXPRESS_API + "/api/event/get_master"
  ).then((response) => {
    //console.log(response);
    if (response.error) {
      setEventList({ loading: false, data: [], message: response.error });
    } else if (response.data && response.status === 200) {
      const result = response.data;
      setEventList({
        loading: false,
        data: result,
        message: "Successfully",
      });

    } else {
      setEventList({ loading: false, data: [], message: "Failed saved" });
    }
  });
};