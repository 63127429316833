import ExcelJs from "exceljs";
import Moment from "moment";
import { formatRupiah } from "./FormAtributes";

const SheetSectionDetail = (wb, param, myfilename) => {
    const tableHeader = [
        { key: "A", title: "No", width: 10 },
        { key: "B", title: "Fullname", width: 30 },
        { key: "C", title: "Customer ID", width: 30 },
        { key: "D", title: "Email", width: 30 },
        { key: "E", title: "Phone", width: 30 },
        { key: "F", title: "Company", width: 35 },
        { key: "G", title: "Event ID", width: 15 },
        { key: "H", title: "Title", width: 35 },
        { key: "I", title: "Trx No", width: 35 },
        { key: "J", title: "Amount", width: 35 },
        { key: "K", title: "Discount %", width: 35 },
        { key: "L", title: "Discount", width: 35 },
        { key: "M", title: "Charged", width: 35 },
        { key: "N", title: "Coupon", width: 35 },
        { key: "O", title: "Status", width: 35 },
   


    ];

    const headers = tableHeader;
    const ws = wb.addWorksheet("Participant List");
    const cloneHeader = [...headers];
    const newHeader = cloneHeader.map((obj) => {
        //delete obj.title;
        var x = {};
        x.key = obj.key;
        x.width = obj.width;
        return x;
    });
    ws.columns = newHeader;

    ws.getRow(1).getCell("A").value = "Participant List";
    ws.getRow(1).getCell("A").font = {
        bold: true,
        size: 16,
    };

    let rowNumber = 3;
    const rowHeader = ws.getRow(rowNumber);
    tableHeader.map((v, index) => {
        var i = index + 1;
        rowHeader.getCell(i).value = v.title;
        rowHeader.getCell(i).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
        }
        rowHeader.getCell(i).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "191970" },
        };
        rowHeader.getCell(i).alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        rowHeader.getCell(i).font = {
            bold: true,
            size: 11,
            color: { argb: "FFFFFF" },
        };
    })


    const momentTime = (time) => {
        const [hours, minutes, seconds] = time.split(':');
        const timeMoment = Moment({ hours, minutes, seconds });
        const formattedTime = timeMoment.format("h:mm a");
        return (
            formattedTime
        );
    };
    const participant = (data) => {
        return `${data.customer_id} / ${data.fullname}\n${data.email} / ${data.phone}`;
      };

    //BODY - PARAMETER
    rowNumber = rowNumber + 1;
    var num = 1;
    param.map((v) => {
        //if (v.coupon_code) {
            const row = ws.getRow(rowNumber);
            row.getCell(1).value = (num++);
            row.getCell(2).value = (v.fullname) ? v.fullname : '';
            row.getCell(3).value = (v.customer_id) ? v.customer_id : '';
            row.getCell(4).value = (v.email) ? v.email : '';
            row.getCell(5).value = (v.phone) ? v.phone : '';
            row.getCell(6).value = (v.origin) ? v.origin : '';
            row.getCell(7).value = (Object.values(v.ev).length > 0) ? v.ev.event_id : "";
            row.getCell(8).value = (Object.values(v.ev).length > 0) ? decodeURIComponent(v.ev.short_title) : "";
            row.getCell(9).value = (v.inv_no) ? v.inv_no : '';
            row.getCell(10).value = (v.inv_amount) ? formatRupiah(parseInt(v.inv_amount)) : '';
            row.getCell(11).value = (v.dsc_persent) ? (parseInt(v.dsc_persent)+'%')  : '';
            row.getCell(12).value = (v.dsc_nominal) ? formatRupiah(parseInt(v.dsc_nominal)) : '';
            row.getCell(13).value = (v.inv_amount_charged) ? formatRupiah(parseInt(v.inv_amount_charged)) : '';
            row.getCell(14).value = (v.coupon_code) ? v.coupon_code : '';
            row.getCell(15).value = (v.status === 'PAID') ? 'Paid' : (v.status === 'PENDING') ? 'API failed' : 'Unpaid';
            

            rowNumber++;
       // }
    });
    //END BODY - PARAMETER


}

const DownloadReport = (obj, myfilename) => {
    const sectionDetail = obj;
    let computedData = [];
    if (Object.values(sectionDetail).length > 0) {
      computedData = sectionDetail.map((v, index) => {
        var obj = v;
        if (v.ev) {
          obj.event_id = v.ev.event_id;
        } else {
          obj.event_id = null; // or some default value
        }
        return obj;
      });
    }
    const sortedData = [...computedData].sort((b, a) => {
        const nameA = a.event_id;
        const nameB = b.event_id;
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });

    const wb = new ExcelJs.Workbook();
    wb.creator = "EVENT-SGU";
    wb.lastModifiedBy = "EVENT-SGU-System";
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();

    SheetSectionDetail(wb, sortedData, myfilename);



    const writeFile = (fileName, content) => {
        const link = document.createElement("a");
        const blob = new Blob([content], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        link.download = fileName;
        link.href = URL.createObjectURL(blob);
        link.click();
    };

    wb.xlsx.writeBuffer().then((buffer) => {
        writeFile(myfilename + " " + Moment().format("DD-MMM-YY"), buffer);
    });
}

export default DownloadReport;